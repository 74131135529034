
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import BaseButton from './base/BaseButton.vue'
import BaseHeadline from './base/BaseHeadline.vue'
import BaseGridLayout from './layouts/BaseGridLayout.vue'
import { globalLabelAsString } from '../shared/general/services/StoreService'

@Component({
  name: 'NextLocationsMVP',
  components: {
    BaseButton,
    BaseHeadline,
    BaseGridLayout,
  },
})
export default class NextLocationsMVP extends Vue {
  @Prop() link ?: string

  private get headline () : string {
    return globalLabelAsString('location_next_locations_headline')
  }

  private get buttonLabel () : string {
    return globalLabelAsString('location_next_locations_show_all_locations_label')
  }
}
